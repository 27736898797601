import { IInputProps } from "../../interfaces/IInputProps";
import { IModel } from "../../interfaces/IModel";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import { useFormState } from "react-hook-form";
import { useState } from "react";
import ErrorInput from "./ErrorInput";

const EmailInput = ({
  label,
  attribute,
  register,
  control,
  rules = {},
  size,
  placeholder,
  type,
  ...props
}: IInputProps<IModel>) => {
  const [touched, setTouched] = useState(false);
  const { errors } = useFormState({ control });

  rules.pattern = {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: "E-mail inválido",
  };

  return (
    <Form.Group
      className="mb-3 position-relative"
      controlId={`form-${attribute}`}
    >
      <Form.Label>{label}</Form.Label>
      <Form.Control
        size={size || "lg"}
        type={type || "email"}
        placeholder={placeholder || label}
        isInvalid={touched && !!errors[attribute]}
        onFocus={() => (!touched ? setTouched(true) : null)}
        {...register(attribute, rules)}
        {...props}
      />
      <ErrorInput error={errors[attribute]} customMessage={props.customMessage} />
    </Form.Group>
  );
};

export default EmailInput;
