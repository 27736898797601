import { Container, Image } from "react-bootstrap";
import { ListWinners } from "./components/ListWinners";
import { useEffect, useState } from "react";
import FooterComponent from "../../components/FooterComponent";
import VoucherService from "../../services/VoucherService";
import { IVoucher } from "../../interfaces/IVoucher";
import moment from "moment";

const WinnersPage = () => {
  const [list, setList] = useState<IVoucher[]>([]);

  useEffect(() => {
    const untilDate = moment().subtract(1, "day").format("YYYY-MM-DD");

    VoucherService.getListWinnersUntilDate(untilDate).then((res) => {
      setList(res.success ? (res.body as IVoucher[]) : []);
    });
  }, []);

  const showWinnersList = () => {
    let now = Number.parseInt(moment().format("YYYYMMDD"));

    return now > 20240901;
  };

  const getDateUntil = () => {
    return moment().subtract(1, "day").format("DD/MM");
  };

  return (
    <>
      <div className="menupush">
        <section className="sec hero hero_sec hero_cadastro page_ganhadores">
          <div className="hero-inner">
            <Container className="position-relative">
              <div className="d-md-flex mb-4 jjustify-content-md-between ballons">
                <div className="balao balao_trintou">
                  <Image
                    src="./images/balao-trintou.png"
                    className="img-fluid"
                  />
                </div>
                <div className="logo_campaign">
                  <Image
                    src="./images/logo-campanha.png"
                    className="img-fluid"
                  />
                </div>
                <div className="balao balao_premio d-flex flex-column align-items-end">
                  <Image
                    src="./images/balao-premio-todo-dia.png"
                    className="img-fluid balao_premio_todo_dia"
                  />
                  <Image
                    src="./images/balao-ainda-concorra.png"
                    className="img-fluid balao_concorra"
                  />
                </div>
              </div>

              {/* GANHADORES DO SORTEIO SEMANAL */}
              <div className="wrapper-ganhadores ganhadores-sorteio ">
                <h4 className="title title-ganhadores">
                  Ganhadores do Sorteio de R$30Mil
                </h4>
                <div className="next-draw-date d-none">
                  Próximo sorteio: 06/01/2024
                </div>
                <ul className="winners-list text-start">
                  {/* GANHADOR atual */}
                  <li className="winner-item winner-atual">
                    <div className="number">1</div>
                    <div className="draw-date">12/09/2024</div>
                    <div className="winner-name">
                      ANTÔNIO CEZAR EVANGELISTA PURIDADE
                    </div>
                    <div className="winner-cpf">CPF: ***.***.*5-52</div>
                    <div className="winner-number">3981700</div>
                    <div className="valor">R$30.000,00</div>
                  </li>

                  {/* GANHADOR ANTERIOR 
                  <li className="winner-item ">
                    <div className="number">2</div>
                    <div className="draw-date">10/10/2024</div>
                    <div className="winner-name">nome</div>
                    <div className="winner-cpf">CPF: ***.***.*9-56</div>
                    <div className="winner-number">123456789</div>
                    <div className="valor">R$30.000,00</div>
                  </li> */}
                </ul>
              </div>

              {/* GANHADORES DO SORTEIO INSTANTÂNEO */}
              <div className="wrapper-ganhadores ganhadores-vales">
                <h4 className="title title-ganhadores">
                  Ganhadores do Vale-compras de R$500,00
                </h4>

                {showWinnersList() ? (
                  <>
                    <h5 className="">
                      Ganhadores de 01/09 até {getDateUntil()}{" "}
                    </h5>
                    <ListWinners itens={list} />
                  </>
                ) : (
                  <h5 className="">LISTA DISPONÍVEL A PARTIR DE 02/09</h5>
                )}
              </div>
            </Container>
          </div>
          <div className="base_ballons">
            <Image src="./images/balao-base-esquerdo.png" className="" />
            <Image src="./images/balao-base-direito.png" className="ms-auto" />
          </div>
        </section>
      </div>
      <FooterComponent />
    </>
  );
};

export default WinnersPage;
