import { IDrawNumber } from "../../../interfaces/IDrawNumber";
import DateUtils from "../../../utils/DateUtils";

interface IProps {
    item: IDrawNumber;
  }

export const ItemNumber = ({item}:IProps) => {
    return (
      <tr>
        <td>{item.number}</td>
        <td>{DateUtils.toDate(item.createdAt)}</td>     
      </tr>
    );
  };
  