import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";

const AboutComponent = () => {
  return (
    <section className="about sec_about">
      <Container className="position-relative">
        <div className="sec_inner sec_content" id="como-participar">
          <ol className="d-flex flex-column flex-md-row sec_list flex-md-wrap row">
            <li className="list_item col-md-6 col-lg-3">
              <Image src="./images/icon-market.png" className="img-fluid" />
              <div className="list_content">
                <div className="list_content-inner">
                  <span className="highlight">A cada</span> <span className="highlight highlight-lg d-block">R$ 100</span> em compras
                </div>
              </div>
            </li>
            <li className="list_item col-md-6 col-lg-3">
              <Image src="./images/icon-note.png" className="img-fluid" />
              <div className="list_content">
                <div className="list_content-inner">
                  <span className="highlight ">Cadastre</span> a sua nota no site
                </div>  
              </div>
            </li>
            <li className="list_item col-md-6 col-lg-3">
              <Image src="./images/icon-present.png" className="img-fluid" />
              <div className="list_content">
                <div className="list_content-inner">
                  Abra o presente e concorra a <span className="highlight ">prêmios!</span>
                </div>
              </div>
            </li>
            <li className="list_item col-md-6 col-lg-3">
              <Image src="./images/icon-hand.png" className="img-fluid" />
              <div className="list_content">
                <div className="list_content-inner">
                  2x mais chances com produtos de <span className="highlight ">marcas participantes.</span>
                </div>
              </div>
            </li>
          </ol>
        </div>
      </Container>
    </section>
  );
};

export default AboutComponent;
