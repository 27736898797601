import { FieldValues, useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { IClient } from "../../../interfaces/IClient";
import ClientService from "../../../services/ClientService";
import DateInput from "../../../components/form/DateInput";
import PhoneInput from "../../../components/form/PhoneInput";
import EmailInput from "../../../components/form/EmailInput";
import TextInput from "../../../components/form/TextInput";
import { IWithMessageInfo } from "../../../interfaces/IWithMessage";
import { useState } from "react";
import { validate } from "uuid";

interface IProps {
  client: IClient;
  onClientUpdate: Function;
  onGoBack: Function;
}

const ClientForm = ({ client, onClientUpdate, onGoBack }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    control,
    watch,
  } = useForm({
    defaultValues: {
      birthday: "",
      cel: "",
      name: "",
      email: "",
      confirmEmail:"",
    },
    shouldUseNativeValidation: true,
    shouldFocusError: true,
  });

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true);
    const _client = data as IClient;
    _client.id = client.id;
    _client.cpf = client.cpf;

    const res = await ClientService.updateClient(_client);
    if (res.success) {
      onClientUpdate(res?.body);
    } else {
      Object.keys(res.namespacedMessages).forEach((el) => {
        const errors = res.namespacedMessages[el] as IWithMessageInfo[];
        if (errors.length > 0) {
          const error = errors[0];
          const key = error.fieldName as "birthday" | "cel" | "name" | "email" | "confirmEmail";
          setError(key, { message: error.defaultMessage, type: "value" });
        }
      });
    }
    setIsLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="form_cadastro-user">
      <Form.Group as={Row} className="mb-3" controlId="formCPF">
        <Form.Label>Seu CPF</Form.Label>
        <div className="form-control form-control-lg bg-info">{client.cpf}</div>
      </Form.Group>

      <TextInput
        label="Seu Nome"
        attribute="name"
        register={register}
        control={control}
        rules={{
          required: { value: true, message: "Campo obrigatório" },
          minLength: { value: 3, message: "Mínimo de 3 caracteres" },
          maxLength: {
            value: 255,
            message: "Máximo de 255 caracteres",
          },
        }}
        placeholder={client.name || "Digite seu nome"}
      />

      {client.email ? (
        <Form.Group as={Row} className="mb-3" controlId="formEmail">
          <Form.Label>Seu E-mail</Form.Label>
          <div className="form-control form-control-lg bg-info">
            {client.email}
          </div>
        </Form.Group>
      ) : (
        <Col>
          <Row>
            <EmailInput
              label="Seu E-mail"
              attribute="email"
              register={register}
              control={control}
              rules={{
                required: { value: true, message: "Campo obrigatório" },
              }}
              placeholder={client.email || "Digite seu melhor e-mail"}
            />
          </Row>
          <Row>
            <EmailInput
              label="Confirme seu E-mail"
              attribute="confirm-email"
              register={register}
              control={control}
              rules={{
                required: { value: true, message: "Campo obrigatório" },
                validate: (val:string) => {
                  if (watch('email') != val) {
                    return false;
                  }
                }
              }}
              customMessage={"E-mails devem ser iguais"}
              placeholder={client.email || "Confirme seu E-mail"}
            />
          </Row>
        </Col>
      )}

      <PhoneInput
        label="Seu Celular"
        attribute="cel"
        register={register}
        control={control}
        rules={{
          required: { value: true, message: "Campo obrigatório" },
          minLength: { value: 15, message: "Formato inválido" },
        }}
        placeholder={client.cel || "Digite seu Celular"}
      />

      <DateInput
        label="Sua data de nascimento"
        attribute="birthday"
        register={register}
        control={control}
        rules={{
          required: { value: true, message: "Campo obrigatório" },
          minLength: { value: 10, message: "Formato inválido" },
        }}
        isDatePicker={false}
        placeholder={client.birthday || "Digite sua data de nascimento"}
      />

      <Row>
        {client && client.name && (
          <Col>
            <Button
              variant="primary"
              onClick={() => onGoBack()}
              className="btn-lg w-100 mt-2"
            >
              Cancelar
            </Button>
          </Col>
        )}
        <Col>
          <Button
            variant="secondary"
            type="submit"
            className="btn-lg w-100 mt-2"
            disabled={isLoading}
          >
            {isLoading ? "Enviando…" : "ATUALIZAR"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ClientForm;
