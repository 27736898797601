import { Col, Container, Image, Row, Accordion } from "react-bootstrap";
import PageContentService from "../../../services/PageContentService";
import { useEffect, useState } from "react";
import { IPageContent } from "../../../interfaces/IPageContent";
import PageContent from "../../../components/PageContent";


const FaqComponent = () => {
  

  return (
    <section id="faq" className="sec sec_faq">
      <Container>
        <div className="ballons_top">
          <Image
            src="./images/balao-premio-todo-dia-home.png"
            className="ballon_premio"
          />
          <Image
            src="./images/balao-ainda-concorra-home.png"
            className="ballon_concorra"
          />
        </div>
        <div className="sec_content">
          <header className="sec_header mb-4">
            <h2 className="title sec_title">PERGUNTAS FREQUENTES</h2>
          </header>
          <Row>
            <Col lg="10">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Do que eu preciso para concorrer?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>Para participar da promoção <b>“Aniversário Atakarejo”</b>, o cliente deve informar seu CPF no início da compra, efetuarem compras a partir de R$ 100,00 (cem reais) nas lojas Atakarejo participante da promoção, contendo ao menos um produto das marcas participantes da promoção e efetuar o cadastro na promoção através do Web App <a href="https://www.aniversarioatakarejo.com.br" target="_blank">www.aniversarioatakarejo.com.br</a>.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Posso cadastrar uma nota de qualquer data de compra?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      As compras feitas no período de 01 de setembro de 2024 até o dia 30 de setembro de 2024. Poderá ser contemplado com um dos brindes da promoção. Lembrando que cada nota só poderá ser cadastrada uma única vez.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    Posso me cadastrar mais de uma vez?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Não há limite de chances por participante, podendo concorrer quantas vezes realizar as compras acima de R$100 (cem reais), desde que atenda as condições previstas no regulamento.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Qual o prêmio e como eu posso utilizá-lo?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      O prêmio é de 01 (um) vale-compras Atakarejo no valor de R$ 500,00 (quinhentos reais), sem função de saque, somente para ser utilizado nas compras em loja, vinculado ao CPF do participante, e 01 (um) prêmio de R$ 30 mil (trinta mil reais) que será sorteado pela loteria federal.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    Pessoas de qualquer idade podem participar?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Qualquer pessoa física com idade igual ou superior a 18 (dezoito) anos pode participar.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    Ganhei o vale compras mais quero dar para outra pessoa, é possível?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Ficam os participantes cientes desde já, que a participação na promoção é individualizada e não poderá, em hipótese alguma, transferir e/ou dividir com outro participante qualquer valor residual, independentemente do grau de parentesco e/ou amizade. Da mesma forma, não será admitida, por força legislação fiscal, “divisão de valores de notas fiscais” entre participantes no ato da compra.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default FaqComponent;
