import moment from "moment";

export enum DateFormatEnum {
    FRONT_DATE = 'DD/MM/YYYY',
    FRONT_DATE_TIME = 'DD/MM/YYYY HH:II:SS'
}

namespace DateUtils {
    export const toDate = (date: Date | string) => {
        const te =  moment(date);
        const t = te.format(DateFormatEnum.FRONT_DATE);
        return t;
    }

    export const toDatetime = (date: Date | string) => {
        return moment(date).format(DateFormatEnum.FRONT_DATE_TIME)
    }

    export const toDB = (date: Date | string) => {
        return new Date();
    }
}

export default DateUtils;