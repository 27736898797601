import { Alert } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";

const TryAgainComponent = () => {
  return (
    <div className="end-game lose end-game-content">
      <div className="animation">
      <video src="./videos/perdeu.mp4" autoPlay muted width={`100%`} ></video>
        {/* <Image src="./videos/perdeu.gif" className="img-fluid" /> */}
      </div>
      <div className="text-result">
        <Alert variant="info mt-1 rounded">
          <span>NÃO FOI DESSA VEZ!</span> <br />
          TENTE NOVAMENTE.
        </Alert>        
      </div>
    </div>
  );
};

export default TryAgainComponent;
