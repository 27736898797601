import ListGroup from "react-bootstrap/ListGroup";
import { IVoucher } from "../../../interfaces/IVoucher";
import DateUtils from "../../../utils/DateUtils";

interface IProps {
  itens: IVoucher[];
}

export const ListWinners = ({ itens }: IProps) => {
  return (
    <ListGroup as="ul" className="list-ganhadores d-flex text-start">
      {itens.map((item) => (
        <ListGroup.Item as="li" className="ganhador">
          <div className="nome">
            {item?.gameOpportunity?.invoice?.client?.name || "-"}
          </div>
          <div className="cpf">
            CPF: {item?.gameOpportunity?.invoice?.client?.cpf || "-"}
          </div>
          <div className="data">{DateUtils.toDate(item?.drawDate) || "-"}</div>
          <div className="valor">R$500,00</div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};
