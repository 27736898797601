import { NotificationManager } from "../components/notifications";
import api from "../http-common";
import { IVoucher } from "../interfaces/IVoucher";
import { IWithMessage } from "../interfaces/IWithMessage";

const endpointPath: string = "/v1/voucher";

namespace VoucherService {
  export const getListWinners = async () => {
    try {
      const resp = await api.get(
        `${endpointPath}/list-only-winners`
      );

      return resp?.data as IWithMessage<IVoucher[]>;
    } catch (e) {
      NotificationManager.error(
        "Erro no servidor ao tentar recuperar os números.",
        "Erro ao recuperar",
        3000
      );
      return e as IWithMessage<string>;
    }
  };

  export const getListWinnersUntilDate = async (date: string) => {
    try {
      const resp = await api.get(
        `${endpointPath}/list-only-winners-until-date/${date}`
      );

      return resp?.data as IWithMessage<IVoucher[]>;
    } catch (e) {
      NotificationManager.error(
        "Erro no servidor ao tentar recuperar os números.",
        "Erro ao recuperar",
        3000
      );
      return e as IWithMessage<string>;
    }
  };
}
export default VoucherService;
