import api from "../http-common";
import { IGameOpportunity } from "../interfaces/IGameOpportunity";
import { IWithMessage } from "../interfaces/IWithMessage";

const endpointPath: string = "/v1/game-opportunity";

namespace GameOpportunityService {
  export const countGameChances = async (token: string) => {
    try {
      const resp = await api.get(`${endpointPath}/total-by-client/${token.trim()}`);
      return resp?.data as IWithMessage<number>;
    } catch (e) {
      return e as IWithMessage<string>;
    }
  };

  export const newGame = async (token: string) => {
    try {
      const resp = await api.get(`${endpointPath}/new-game/${token.trim()}`);
      return resp?.data as IWithMessage<IGameOpportunity>;
    } catch (e) {
      return e as IWithMessage<string>;
    }
  };
}

export default GameOpportunityService;
