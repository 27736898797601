import Form from "react-bootstrap/Form";
import { IInputProps } from "../../interfaces/IInputProps";
import { IModel } from "../../interfaces/IModel";
import InputMask from "react-input-mask";
import DatePicker, { registerLocale } from "react-datepicker";
import { ptBR } from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useFormState } from "react-hook-form";
import { useState } from "react";
import ErrorInput from "./ErrorInput";
registerLocale("pt-BR", ptBR);

interface IProps extends IInputProps<IModel> {
  isDatePicker: boolean;
}

const DateInput = ({
  label,
  attribute,
  register,
  control,
  rules = {},
  isDatePicker = false,
  size,
  placeholder,
  type,
  ...props
}: IProps) => {
  const [touched, setTouched] = useState(false);
  const { errors } = useFormState({ control });

  const isDateValidate = (value: string) => {
    if (value.length === 10) {
      const date = value.split("/").reverse().join("-");
      return moment(date).isValid();
    }
    return false;
  };

  if (!isDatePicker) rules.validate = isDateValidate;

  return (
    <Form.Group
      className="mb-3 position-relative"
      controlId={`form${attribute}`}
    >
      <Form.Label>{label}</Form.Label>
      {isDatePicker ? (
        <Form.Control
          locale="pt-BR"
          dateFormat="P"
          as={DatePicker}
          size={size || "lg"}
          type={type || "tel"}
          placeholder={placeholder || label}
          isInvalid={touched && !!errors[attribute]}
          onFocus={() => (!touched ? setTouched(true) : null)}
          {...register(attribute, rules)}
          {...props}
        />
      ) : (
        <Form.Control
          size={size || "lg"}
          type={type || "tel"}
          as={InputMask}
          mask="99/99/9999"
          placeholder={placeholder || label}
          isInvalid={touched && !!errors[attribute]}
          onFocus={() => (!touched ? setTouched(true) : null)}
          {...register(attribute, rules)}
          {...props}
        />
      )}
      <ErrorInput error={errors[attribute]} />
    </Form.Group>
  );
};

export default DateInput;
