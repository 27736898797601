import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { useNavigate } from "react-router-dom";
import PreRegisterForm from "./components/PreRegisterForm";
import AboutComponent from "./components/AboutComponent";
import BrandsComponent from "./components/BrandsComponent";
import FaqComponent from "./components/FaqComponent";
import RulesComponent from "./components/RulesComponent";
import FooterComponent from "./components/FooterComponent";
import { IClient } from "../../interfaces/IClient";
import {
  CLIENT_STORAGE,
  IS_PRODUCTION,
} from "../../constants/ProjectConstants";
import { useEffect, useState } from "react";
import ClientService from "../../services/ClientService";
import { Spinner } from "react-bootstrap";
import moment from "moment";

const HomePage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [client, setClient] = useState<IClient | undefined>();

  useEffect(() => {
    //localStorage.removeItem(CLIENT_STORAGE);

    const storage = localStorage.getItem(CLIENT_STORAGE);
    let _client: any = undefined;
    if (storage && Object.keys(storage).length > 0) {
      _client = JSON.parse(storage) as IClient;
      _client.countOpportunitties = 0;
      setClient(_client);

      setIsLoading(true);
      ClientService.getClientByToken(_client.token).then((res) => {
        setClient(res.success ? (res.body as IClient) : undefined);
        setIsLoading(false);
      });
    }
  }, []);

  const onSubmitHandler = async (client?: IClient) => {
    if (client) {
      localStorage.setItem(CLIENT_STORAGE, JSON.stringify(client));
    }

    navigate("/cadastro", { state: { client } });
  };

  const onRemoveClientHandler = () => {
    localStorage.removeItem(CLIENT_STORAGE);
    setClient(undefined);
  };

  const onGoToRegisterHandler = () => {
    navigate("/cadastro", { state: { client } });
  };

  const showRegisterForm = () => {
    if (!IS_PRODUCTION) return true;

    let now = Number.parseInt(moment().format("YYYYMMDD"));
    return now >= 20240901;
  };

  return (
    <>
      <div className="menupush">
        <section className="sec hero hero_sec hero_home text-center text-md-start">
          <div className="hero-inner">
            <Container className="position-relative">
              <div className="logo_campaign ">
                <Image src="./images/logo-campanha.png" className="img-fluid" />
              </div>
              <div className="d-md-flex ">
                <div className="order-md-1 form_area ms-md-auto">
                  {showRegisterForm() ? (
                    <PreRegisterForm
                      clientIsLoading={isLoading}
                      client={client}
                      onSubmitHandler={(client?: IClient) =>
                        onSubmitHandler(client)
                      }
                      onRemoveClient={() => onRemoveClientHandler()}
                      onGoToRegister={() => onGoToRegisterHandler()}
                    />
                  ) : (<div>
                    <h3>Campanha começa no dia 01/09/2024</h3>
                  </div>)
                  }
                </div>
                <div className="balao balao_trintou order-md-0">
                  <Image
                    src="./images/balao-trintou-home.png"
                    className="img-fluid"
                  />
                </div>
              </div>
            </Container>
          </div>
        </section>
      </div>
      <AboutComponent />
      <BrandsComponent />
      <RulesComponent />
      <FaqComponent />
      <FooterComponent />
    </>
  );
};

export default HomePage;
