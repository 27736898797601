import { Col, Container, Row, Image } from "react-bootstrap";
import { SelectClientForm } from "./components/SelectClientForm";
import { useState } from "react";
import { ListNumbers } from "./components/ListNumbers";
import FooterComponent from "../../components/FooterComponent";
import { IClient } from "../../interfaces/IClient";
import DrawNumberService from "../../services/DrawNumberService";
import { IDrawNumber } from "../../interfaces/IDrawNumber";
import ClientService from "../../services/ClientService";

const CampaignNumbersPage = () => {
  const [list, setlist] = useState<IDrawNumber[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [client, setClient] = useState<IClient | undefined>();

  /*useEffect(() => {
    const storage = localStorage.getItem(CLIENT_STORAGE);
    let _client: any = undefined;
    if (storage && Object.keys(storage).length > 0) {
      _client = JSON.parse(storage) as IClient;
      if (_client.token) return;

      setClient(_client);
      setIsLoading(true);

      DrawNumberService.getByClientToken(_client.token).then((res) => {
        setlist(res.success ? (res.body as IDrawNumber[]) : []);
        setIsLoading(false);
      });
    }
  }, []);*/

  const onSubmitHandler = (cpf: string) => {
    setIsLoading(true);

    ClientService.getClientByCpf(cpf).then((res) => {
      if (res.success) {
        var _client = res.body as IClient;
        setClient(_client);

        DrawNumberService.getByClientToken(_client.token).then((res) => {
          setlist(res.success ? (res.body as IDrawNumber[]) : []);
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <div className="menupush">
        <section className="sec hero hero_sec hero_cadastro text-center text-md-start">
          <div className="hero-inner">
            <Container className="position-relative">
              <div className="d-md-flex mb-4 jjustify-content-md-between ballons">
                <div className="balao balao_trintou">
                  <Image
                    src="./images/balao-trintou.png"
                    className="img-fluid"
                  />
                </div>
                <div className="logo_campaign">
                  <Image
                    src="./images/logo-campanha.png"
                    className="img-fluid"
                  />
                </div>
                <div className="balao balao_premio d-flex flex-column align-items-end">
                  <Image
                    src="./images/balao-premio-todo-dia.png"
                    className="img-fluid balao_premio_todo_dia"
                  />
                  <Image
                    src="./images/balao-ainda-concorra.png"
                    className="img-fluid balao_concorra"
                  />
                </div>
              </div>
              
                <Row>
                  <Col md="8" className="mx-auto">
                    <SelectClientForm
                      onSubmitHandler={(cpf) => onSubmitHandler(cpf)}
                      isLoading={isLoading}
                    />

                    {client && (
                    <div className="container-numbers mt-4">
                      {list.length > 0 ? <ListNumbers itens={list} /> : `Nenhum número criado`}
                    </div>
                    )}
                  </Col>
                </Row>
              
            </Container>
          </div>
          <div className="base_ballons">
            <Image src="./images/balao-base-esquerdo.png" className="" />
            <Image src="./images/balao-base-direito.png" className="ms-auto" />
          </div>
        </section>
      </div>
      <FooterComponent />
    </>
  );
};

export default CampaignNumbersPage;
