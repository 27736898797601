import { FieldValues, useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputMask from "react-input-mask";
import { Col, Row, Spinner } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import { cpf as cpfValidate } from "cpf-cnpj-validator";

interface IProps {
  onSubmitHandler: (cpf: string) => void;
  isLoading: boolean;
}

export const SelectClientForm = ({ onSubmitHandler, isLoading }: IProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cpf: "",
    },
    shouldUseNativeValidation: true,
    shouldFocusError: true,
  });

  const onSubmit = async (data: FieldValues) => {
    onSubmitHandler(data.cpf);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Row} className="mb-3" controlId="formCPF">
          <Col md="8">
            <Form.Label className="text-start">
              Digite seu CPF cadastrado:
            </Form.Label>
            <Form.Control
              size="lg"
              type="text"
              as={InputMask}
              mask="999.999.999-99"
              placeholder="___.___.___-__"
              {...register("cpf", {
                validate: (data) => {
                  //Campo obrigatório
                  if (!data) return "CPF é um campo obrigatório";

                  //Número mínimo de caractere
                  const numeros = data.replace(/[^\d]+/g, "") || "";
                  if (numeros?.length < 11) return "CPF precisa ser 11 números";

                  //Verifca se CPF é válido
                  return !cpfValidate.isValid(numeros) ? "CPF inválido" : true;
                },
              })}
            />
            <ErrorMessage
              errors={errors}
              name="cpf"
              render={({ message }) => <p>{message}</p>}
            />
          </Col>
          <Col md="4" className="d-md-flex">
            <Button
              variant="primary"
              type="submit"
              className="btn-lg w-100 mt-2 mt-md-auto"
              disabled={isLoading}
            >
              {!isLoading && `Enviar`}
              {isLoading && (
                <Spinner animation="border" role="status" variant="primary">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )}
            </Button>
          </Col>
        </Form.Group>
      </Form>
    </>
  );
};
