import { NotificationManager } from "../components/notifications";
import api from "../http-common";
import { IClient } from "../interfaces/IClient";
import { IWithMessage } from "../interfaces/IWithMessage";

const endpointPath: string = "/v1/client";

namespace ClientService {
  export const getClientByToken = async (token: string) => {
    try {
      const resp = await api.get(`${endpointPath}/find-by-token/${token}`);

      return resp?.data as IWithMessage<IClient>;
    } catch (e) {
      NotificationManager.error(
        "Erro no servidor ao tentar recuperar o cliente.",
        "Erro ao recuperar",
        3000
      );
      return e as IWithMessage<string>;
    }
  };

  export const getClientByCpf = async (cpf: string) => {
    try {
      const resp = await api.get(`${endpointPath}/find-by-cpf/${cpf}`);

      return resp?.data as IWithMessage<IClient>;
    } catch (e) {
      NotificationManager.error(
        "Erro no servidor ao tentar pre-cadastrar.",
        "Erro ao pre-cadastrar",
        3000
      );
      return e as IWithMessage<string>;
    }
  };

  export const updateClient = async (model: IClient) => {
    try {
      if (!model.id) {
        throw new TypeError("ID não informado");
      }

      model.birthday = model.birthday?.split("/").reverse().join("-");

      const resp = await api.put(
        `${endpointPath}/update-client/${model.id}`,
        model
      );

      NotificationManager.success(
        "Atualização realizada com sucesso",
        "Atualizado com sucesso",
        3000
      );
      return resp?.data as IWithMessage<IClient>;
    } catch (e) {
      NotificationManager.error(
        "Erro no servidor ao tentar atualizar.",
        "Erro ao criar",
        3000
      );
      return e as IWithMessage<IClient>;
    }
  };
}
export default ClientService;
