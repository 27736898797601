import { Col, Container, Image, Row } from "react-bootstrap";

const BrandsComponent = () => {
  return (
    <section id="marcas-participantes" className="sec sec_brands">
      <Container>
        <div className="d-md-flex ">
          <header className="sec_header text-center text-md-start">
            <h2 className="title sec_title">Nossos Parceiros</h2>
            <Image src="./images/bolo.png" className="img-fluid cake" />
          </header>

          <div className="marcas-wrapper brands">
                {/* <!-- DIAMANTE --> */}
                <div className="diamante">
                  <div className="g-3">
                    <div className="marca-item">
                          <Image src="./images/marcas/diamante/petra.png" className="logo lazyload g-4" />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item brand_item">
                          <Image src="./images/marcas/diamante/tnt.png" className="logo lazyload g-4" />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                          <Image src="./images/marcas/diamante/seda.png" className="logo lazyload g-4" />
                        </div>
                    </div>                    
                    <div className="g-3">
                        <div className="marca-item">
                          <Image src="./images/marcas/diamante/sadia.png" className="logo lazyload g-4" />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                          <Image src="./images/marcas/diamante/vitarella.png" className="logo lazyload g-4" />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                          <Image src="./images/marcas/diamante/piraque.png" className="logo lazyload g-4" />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                          <Image src="./images/marcas/diamante/mucilon-nestle.png" className="logo lazyload g-4" />
                        </div>
                    </div>
                    
                </div>

                {/* <!-- OURO --> */}
                <div className="ouro">
                    <div className="g-3">
                        <div className="marca-item">
                          <Image src="./images/marcas/ouro/seara.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                        <Image src="./images/marcas/ouro/pippos.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                        <Image src="./images/marcas/ouro/coca.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                        <Image src="./images/marcas/ouro/familiar.png" className="logo lazyload " />
                        </div>
                    </div>                   
                    <div className="g-3">
                        <div className="marca-item">
                        <Image src="./images/marcas/ouro/marilan.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                        <Image src="./images/marcas/ouro/ype.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                        <Image src="./images/marcas/ouro/neve.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                        <Image src="./images/marcas/ouro/bis.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                        <Image src="./images/marcas/ouro/oreo.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                        <Image src="./images/marcas/ouro/spaten.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                        <Image src="./images/marcas/ouro/ekobom.png" className="logo lazyload " />
                        </div>
                    </div>
                </div>
                {/* <!-- PRATA --> */}
                <div className="prata">

                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/downy.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/mauricea.png" className="logo lazyload " />
                      </div>
                  </div>

                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/listerine.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/yopro.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/ox.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/heinz.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/francis.png" className="logo lazyload " />
                      </div>
                  </div>

                  {/* 2 */}
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/chan-delle.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/brandini.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/bemtevi.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/marata.png" className="logo lazyload " />
                      </div>
                  </div>



                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/camil.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/betania.png" className="logo lazyload " />
                      </div>
                  </div>

                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/santa-clara.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/amstel.png" className="logo lazyload " />
                      </div>
                  </div>

                  {/* 3 */}

                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/melitta.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/sbp.png" className="logo lazyload " />
                      </div>
                  </div>
                  
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/raid.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/aurora.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/baly.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/colgate.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/batavo.png" className="logo lazyload " />
                      </div>
                  </div>
                </div>

                {/* <!-- BRONZE --> */}
                <div className="bronze">
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/above.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/rj-alimentos.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/dragao.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/enerup.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/nestle-sorvetes.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/loreal.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/italac.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/micos.png" className="logo lazyload " />
                        </div>
                    </div>

                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/herbissimo.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/sempre.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/natville.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/qboa.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/bombril.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/liza.png" className="logo lazyload " />
                        </div>
                    </div>

                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/ccgl.png" className="logo lazyload " />
                        </div>
                    </div>

                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/babysec.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/forno-de-minas.png" className="logo lazyload " />
                        </div>
                    </div>

                </div>
            </div>

         
        </div>
      </Container>
    </section>
  );
};

export default BrandsComponent;
